
import { defineComponent, ref } from 'vue'
import InfoCards from '@/components/shared/templates/InfoCards.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import type { BreadcrumbsLink } from '@/definitions/shared/types'
import { useModals } from '@/compositions/modals'
import PageContent from '@/components/layout/pageContent/PageContent.vue'

export default defineComponent({
  components: {
    PageContent,
    TmAlert,
    InfoCards,
    TmButton,
    DetailsHero,
    InfoCard,
    InfoCardRow,
  },
  props: {
    isSystem: {
      type: Boolean,
    },
  },
  setup() {
    const { openModal } = useModals()
    const openAssigneeModal = () => {
      openModal('singleAssignee', {
        modalTitle: 'Assignee',
        label: 'Automatically assign to',
        btnText: 'Save',
      })
    }
    const openArchiveModal = () => {
      openModal('confirmation', {
        title: 'Archive',
        text: [
          'By archiving the',
          {
            text: ' Sales US ',
            style: 'semi-bold',
          },
          'pipeline, you don\'t delete deals from it, but users won\'t have access to them until you unarchive the pipeline.',
        ],
        btnText: 'Archive',
      })
    }
    const breadcrumbsLink = ref<BreadcrumbsLink[]>([
      {
        label: 'Deals',
        name: 'base.deals.index',
      },
      {
        label: 'Pipelines',
        name: 'base.deals.settings',
      },
      {
        label: 'Sales US',
      },
    ])
    const isArchived = ref(false)
    const handleArchive = () => {
      if (!isArchived.value) {
        openArchiveModal()
      }
      isArchived.value = !isArchived.value
    }
    const openDeleteModal = () => openModal('confirmation', {
      title: 'Delete pipeline',
      text: [
        {
          text: 'Sales US',
          style: 'semi-bold',
        },
        ' pipeline and ',
        {
          text: 'all deals',
          style: 'semi-bold',
        },
        ' will be permanently deleted. This action cannot be undone.\n\n Are you sure you would like to delete the selected pipeline?',
      ],
      btnText: 'Delete pipeline',
      btnColor: 'error',
    })

    return {
      isArchived,
      handleArchive,
      openDeleteModal,
      breadcrumbsLink,
      openArchiveModal,
      openAssigneeModal,
      openModal,
    }
  },
})
